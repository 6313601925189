import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'


export default class extends Controller {
  static targets = ["popupMenu"];

  connect() {
    if (this.hasPopupMenuTarget) {
      useClickOutside(this, {element: this.popupMenuTarget});
    }

    this.placeNotificationBadges();
  }

  togglePopupMenu() {
    if (this.popupMenuTarget.classList.contains("visible")) {
      this.popupMenuTarget.classList.remove("visible")
    } else {
      setTimeout(() => {
        this.popupMenuTarget.classList.add("visible");
      }, 100);
    }
  }

  clickOutside(event) {
    this.popupMenuTarget.classList.remove("visible");
  }

  placeNotificationBadges() {
    if (document.querySelector('.popup-menu .notification-badge')) {
      document.querySelector('.navbar-menu-button').innerHTML = '<div class="notification-badge"></div>';
    }
  }
}
