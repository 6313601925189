import { Controller } from "stimulus"
import IMask from 'imask';


export default class extends Controller {
  connect() {
    var maskOptions = {
      mask: this.element.dataset.mask
    };
    var mask = IMask(this.element, maskOptions);
  }
}
