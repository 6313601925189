import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab"];

  connect() {
    this.element.querySelector('.item').click();
  }

  switch(e) {
    e.preventDefault();

    for (let link of this.element.querySelectorAll('.item')) {
      if (e.currentTarget == link) {
        link.classList.add('active')
      } else {
        link.classList.remove('active')
      }
    }

    for (let tab of this.tabTargets) {
      if (tab.dataset.tab == e.currentTarget.href.split('#')[1]) {
        tab.classList.add('active');
      } else {
        tab.classList.remove('active');
      }
    }
  }
}
