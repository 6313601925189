import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'


export default class extends Controller {
  static targets = ["kaspiGuide", "bankGuide", "tariffDependentImage"];

  connect() {
    for (var input of this.element.querySelectorAll('input[type=radio]')) {
      input.addEventListener('change', this.showSelectedGuide.bind(this));
    }
  }

  showSelectedGuide() {
    var selectedPaymentMethodInput = this.element.querySelector('input[name=payment_method][type=radio]:checked');
    if (selectedPaymentMethodInput) {
      switch (selectedPaymentMethodInput.value) {
        case 'kaspi_pay':
          this.kaspiGuideTarget.classList.remove('hidden');
          this.bankGuideTarget.classList.add('hidden');
          break;
        case 'bank':
          this.kaspiGuideTarget.classList.add('hidden');
          this.bankGuideTarget.classList.remove('hidden');
          break;
      }
    }

    var selectedTariff = this.element.querySelector('input[name=tariff][type=radio]:checked').value;
    if (selectedTariff) {
      for (var img of this.tariffDependentImageTargets) {
        if (img.dataset.tariff == selectedTariff) {
          img.style.display = 'block';
        } else {
          img.style.display = 'none';
        }
      }  
    }
  }
}
