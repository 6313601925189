import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      history.back();
    })
  }
}
