import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('ajax:success', (e) => {
      for (var form of document.querySelectorAll('.connect-form')) {
        form.classList.add('success')
      }
    })
  }
}
