import { Controller } from "stimulus"
import MicroModal from 'micromodal'
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default class extends Controller {
  static values = {
    disableScroll: {type: Boolean, default: false}
  }

  connect() {
    this.element.id = "modal" + Math.random().toString(16).slice(2);
    for (var ct of this.element.querySelectorAll('[data-micromodal-close]')) {
      ct.addEventListener("click", () => {
        MicroModal.close(this.element.id);
      })
    }

    MicroModal.show(this.element.id, {
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      disableScroll: this.disableScrollValue,
      onClose: (modal, triggerElement) => {
        setTimeout(() => {
          if (document.querySelector('.modal.is-open[data-modal-disable-scroll-value=true]')) {
            document.querySelector('html').style.overflow = 'hidden';
          } else {
            document.querySelector('html').style.overflow = '';
          }
        }, 400);
      }
    });
  }
}
