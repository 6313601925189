import { Controller } from "stimulus"

export default class extends Controller {
  delete() {
    let removeLButton = this.element
    let confirmRemove = removeLButton.dataset.removeConfirm
    let supplierId = removeLButton.dataset.supplierId
    let imageId = removeLButton.dataset.imageId
    if (confirm(confirmRemove)) {
      let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      let url = `/sc/suppliers/${supplierId}/images/${imageId}`
      var request = new XMLHttpRequest();
      request.open("DELETE", url);
      request.setRequestHeader('X-CSRF-Token', csrfToken)
      request.onreadystatechange = function() {
        removeLButton.closest(".image").remove();
      }
      request.send();
    }
  }
}
