import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]
  connect() {
    console.log("Checkbox controller connected")
    console.log(this.checkboxTargets)
  }

  checkAll() {
    console.log("Check all")
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = true
    })
  }

  unCheckAll() {
    console.log("unCheck all")
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
  }
}
