import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["revealable"];

    connect() {
    }

    reveal() {
        if (this.hasRevealableTarget) {
            this.revealableTarget.classList.add("revealed");
        }
    }

    hide() {
        if (this.hasRevealableTarget) {
            this.revealableTarget.classList.remove("revealed");
        }
    }
}
