import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static values = {
    menuTarget: String
  }

  connect() {
    this.menu = document.querySelector(this.menuTargetValue);
    useClickOutside(this, {element: this.menu});
  }

  show(e) {
    e.preventDefault();
    e.stopPropagation();

    if (document.querySelectorAll(".visible.context-menu").length > 0) {
      for (var el of document.querySelectorAll(".visible.context-menu")) {
        console.log(el);
        el.classList.remove("visible");
      }
    }

    setTimeout(() => {
      this.menu.classList.add("visible");
    }, 100);
  }

  clickOutside(event) {
    this.menu.classList.remove("visible");
  }
}
