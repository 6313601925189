import { Controller } from "stimulus"
import MicroModal from 'micromodal'
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import guid from '../src/guid';

export default class extends Controller {
  static values = {
    layout: String
  }

  connect() {
    this.element.addEventListener("click", (e) => {
      e.preventDefault();
      this.open(e)
    });
  }

  open(e) {
    e.preventDefault();

    var id = `modal_${guid()}`;
    this.modal = document.createElement('div');
    this.modal.className = `modal micromodal-slide ${this.layoutValue}`;
    this.modal.ariaHidden = true;
    this.modal.setAttribute('data-controller', 'modal');
    this.modal.setAttribute('data-modal-disable-scroll-value', true);
    this.modal.id = id;
    this.modal.innerHTML = `
      <div class="modal__overlay" tabindex="-1">
        <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
          <div class="modal__header">
            ${this.element.title}
            <button class="modal__close" data-micromodal-close></button>
          </div>
          <div class="modal__content">
            
          </div>
        </div>
      </div>`;

    this.modal.addEventListener('result', (e) => {
      let event = new CustomEvent('result', {detail: e.detail});
      this.element.dispatchEvent(event);
      MicroModal.close(this.modal.id);
    });

    document.body.appendChild(this.modal);

    fetch(this.element.href, {
      headers: {
        'X-Layout': 'modal'
      }
    })
      .then(response => response.text())
      .then(html => this.modal.querySelector('.modal__content').innerHTML = html);
  }
}
