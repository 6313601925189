import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import { format } from 'date-fns'


export default class extends Controller {
  static values = {
    previewUrl: String,
    daysUrl: String
  }
  static targets = ["datepicker", "previewView", "form"];

  connect() {
    const Kazakh = require("flatpickr/dist/l10n/kz.js").default.kz;
    const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;

    this.fp = flatpickr(this.datepickerTarget, {
      locale: document.body.dataset.locale == "ru" ? Russian : Kazakh,
      inline: true,
      onChange: (selectedDates, dateStr, instance) => {
        this.selectDay(selectedDates[0]);
      },
      onMonthChange: (selectedDates, dateStr, instance) => {
        this.updateEligibleDays(instance.currentMonth + 1, instance.currentYear);
      },
      enable: []
    });
    this.updateEligibleDays(this.fp.currentMonth + 1, this.fp.currentYear);
  }

  updateEligibleDays(month, year) {
    var allDays = [...this.fp.days.querySelectorAll('.flatpickr-day')].map(element => element.dateObj);
    var daysUrlValue = new URL(this.daysUrlValue);
    daysUrlValue.searchParams.append('from', format(allDays[0], "yyyy-MM-dd"))
    daysUrlValue.searchParams.append('to', format(allDays[allDays.length-1], "yyyy-MM-dd"))
    fetch(daysUrlValue.href)
      .then(response => response.json())
      .then(data => {
        this.fp.set("enable", data.map((menu) => menu.date));
      });
  }

  selectDay(date) {
    var previewUrlValue = new URL(this.previewUrlValue);
    if (previewUrlValue.searchParams.get('show_week')) {
      previewUrlValue.searchParams.append('date', format(date, "yyyy-MM-dd"));
      this.previewViewTarget.src = previewUrlValue.href;
    } else {
      this.previewViewTarget.src = `${this.previewUrlValue}/${format(date, "yyyy-MM-dd")}`;
    }
    this.formTarget.querySelector("#copy_day_from").value = format(date, "yyyy-MM-dd");
    this.previewViewTarget.loaded.then(() => {
      this.element.classList.add("day-selected");
    });
  }

  selectAnotherDay(e) {
    e.preventDefault();
    this.element.classList.remove("day-selected");
  }
}
