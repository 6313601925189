import { Controller } from "stimulus"


export default class extends Controller {
    static targets = [
      "input", "suggestionsList", "sectionInput", "priceFields", "imageButton", "imageInput"];

    static values = {
        searchUrl: String,
        isFree: Boolean
    }

    connect() {
        this.onSectionSelect();
    }

    search(e) {
        let sectionId = this.sectionInputTarget.value;
        let q = this.inputTarget.value;
        fetch(this.searchUrlValue + `?q=${q}&section_id=${sectionId}`)
            .then((response) => {
                return response.text();
            })
            .then((html) => {
                this.suggestionsListTarget.innerHTML = html;
                this.suggestionsListTarget.classList.add("visible");
            });
    }

    onSectionSelect(e) {
        if (!this.hasSectionInputTarget) { return; }
        var sectionOption = this.sectionInputTarget.options[this.sectionInputTarget.selectedIndex];
        if (sectionOption.dataset.isFree) {
            this.setFree();
        } else {
            this.setPaid();
        }
    }

    hideSuggestions() {
        setTimeout(() => {
            this.suggestionsListTarget.classList.remove("visible");
        }, 500);
    }

    setPaid(e) {
        this.isFreeValue = false;
        for (var input of this.priceFieldsTarget.querySelectorAll("input")) {
            input.disabled = false;
        }
    }

    setFree(e) {
        this.isFreeValue = true
        for (var input of this.priceFieldsTarget.querySelectorAll("input")) {
            input.disabled = true;
        }
    }

    onImageSelect(e) {
        this.setImage(e.detail)
    }

    setImage(img) {
        this.imageButtonTarget.style.backgroundImage = `url(${img.url})`;
        this.imageButtonTarget.classList.remove("empty");
        this.imageInputTarget.value = img.id;
    }

    removeImage(e) {
        e.preventDefault();
        e.stopPropagation();
        this.imageInputTarget.value = "";
        this.imageButtonTarget.style.backgroundImage = "none";
        this.imageButtonTarget.classList.add("empty");
    }
}
