import { Controller } from "stimulus"
import { useTargetMutation } from 'stimulus-use'


export default class extends Controller {
  static targets = ["day"];

  connect() {
    useTargetMutation(this, {targets: ["day"]});
  }

  dayTargetChanged(element) {
    // console.log(element);
  }
}
