import { Controller } from "stimulus"

export default class extends Controller {
  update(e) {
    let schoolId = this.element.dataset.schoolId
    let xhr = new XMLHttpRequest()
    let personId = e.target.value
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    xhr.open('PUT', `/cp/schools/${schoolId}/free_meals?person_id=${personId}`, true )
    xhr.setRequestHeader('X-CSRF-Token', csrfToken)
    xhr.onreadystatechange = function() {
      if (xhr.readyState !== 4 || xhr.status !== 200) {
        return;
      }
    }
    xhr.send()
  }

  checkedAllInGroup(e) {
    console.log(e)
    let schoolId = this.element.dataset.schoolId
    let xhr = new XMLHttpRequest()
    let groupId = e.target.dataset.groupId
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    let checboxes = document.querySelectorAll(`input[data-group-id="${groupId}"]`)
    checboxes.forEach(checkbox => {
      checkbox.checked = 'checked'
    })
    xhr.open('PUT', `/cp/schools/${schoolId}/free_meals/update_all/${groupId}?free_meals=true`, true )
    xhr.setRequestHeader('X-CSRF-Token', csrfToken)
    xhr.onreadystatechange = function() {
      if (xhr.readyState !== 4 || xhr.status !== 200) {
        return;
      }
    }
    xhr.send()
  }

  uncheckedAllInGroup(e) {
    console.log(e)
    let schoolId = this.element.dataset.schoolId
    let xhr = new XMLHttpRequest()
    let groupId = e.target.dataset.groupId
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    let checboxes = document.querySelectorAll(`input[data-group-id="${groupId}"]`)
    checboxes.forEach(checkbox => {
      checkbox.checked = ''
    })
    xhr.open('PUT', `/cp/schools/${schoolId}/free_meals/update_all/${groupId}?free_meals=false`, true )
    xhr.setRequestHeader('X-CSRF-Token', csrfToken)
    xhr.onreadystatechange = function() {
      if (xhr.readyState !== 4 || xhr.status !== 200) {
        return;
      }
    }
    xhr.send()
  }

  accordionToggle(e) {
    let accordionButton = e.target
    let accordion = accordionButton.closest(".accordion")
    let panel = accordion.querySelector(".panel");
    let arrowUp = accordion.querySelector(".up")
    let arrowDown = accordion.querySelector(".down")

    if (accordionButton.classList.contains("active")) {
      accordionButton.classList.remove("active");
      panel.classList.remove("active");
      arrowUp.classList.remove("active");
      arrowDown.classList.add("active");
    } else {
      accordionButton.classList.add("active");
      panel.classList.add("active");
      arrowUp.classList.add("active");
      arrowDown.classList.remove("active");
    }
  }
}
