import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["form"];

    presave() {
        var formData = new FormData(this.formTarget);
        var request = new XMLHttpRequest();
        formData.set("commit", "presave");
        request.open("POST", this.formTarget.action);
        request.send(formData);
    }
}
