import { Controller } from "stimulus"
import simpleParallax from "simple-parallax-js"

export default class extends Controller {
  connect() {
    new simpleParallax(this.element, {
      orientation: 'left',
      scale: 1.2,
      overflow: true,
      delay: 0,
      transition: 'linear'
    });
  }
}
