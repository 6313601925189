import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["scrollView", "scrollContent", "selectedDay"];

  connect() {
    this.scrollViewTarget.addEventListener("mousedown", (e) => {
      this.scrollPosition = {
        left: this.scrollViewTarget.scrollLeft,
        x: e.clientX
      }

      var mouseMoveFn = (e) => {
        if (!this.element.classList.contains("dragging")) {
          this.element.classList.add("dragging");
        }

        var dx = e.clientX - this.scrollPosition.x;
        this.scrollViewTarget.scrollLeft = this.scrollPosition.left - dx;
      }
      
      var mouseUpFn = (e) => {
        this.element.classList.remove("dragging");
        document.removeEventListener("mousemove", mouseMoveFn);
        document.removeEventListener("mousemove", mouseUpFn);
      }

      document.addEventListener("mousemove", mouseMoveFn);
      document.addEventListener("mouseup", mouseUpFn);
    });

    var selectedDayXOffset = this.selectedDayTarget.offsetLeft,
        scrollViewWidth = this.scrollViewTarget.offsetWidth,
        dayWidth = this.selectedDayTarget.offsetWidth;

    // Scroll so that selected day is in the middle
    this.scrollViewTarget.scrollTo(selectedDayXOffset + dayWidth/2 + 4 - scrollViewWidth/2, 0);
  }

  strideRight() {
    this.scrollViewTarget.style.scrollBehavior = "smooth";
    this.scrollViewTarget.scrollTo(this.scrollViewTarget.scrollLeft + this.scrollContentTarget.offsetWidth/3, 0);
    this.scrollViewTarget.style.scrollBehavior = "auto";
  }

  strideLeft() {
    this.scrollViewTarget.style.scrollBehavior = "smooth";
    this.scrollViewTarget.scrollTo(this.scrollViewTarget.scrollLeft - this.scrollContentTarget.offsetWidth/3, 0);
    this.scrollViewTarget.style.scrollBehavior = "auto";
  }
}
