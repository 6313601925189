import { Controller } from "stimulus"
import Dropzone from "dropzone"


export default class extends Controller {
  static targets = ["items", "uploadButton", "uploadContainer", "uploadPreview"];

  static values = {
    uploadUrl: String
  };

  connect() {
    let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.dropzone = new Dropzone(this.element, {
      url: this.uploadUrlValue,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      maxFiles: null,
      parallelUploads: 1,
      maxFilesize: 256,
      acceptedFiles: "image/*",
      addRemoveLinks: false,
      autoQueue: true,
      previewsContainer: this.uploadContainerTarget,
      clickable: this.uploadButtonTarget,
      previewTemplate: this.uploadPreviewTarget.outerHTML
    });

    this.dropzone.on("success" , (file, response) => {
      file.previewElement.remove();

      var div = document.createElement('div');
      div.innerHTML = response.html.trim();
      this.itemsTarget.append(div.firstChild);
    })
  }

  pick(e) {
    let event = new CustomEvent("result", {detail: e.params});
    this.element.closest('[data-controller=modal]').dispatchEvent(event);
  }

  delete(e) {
    if (confirm("Удалить файл?")) {
      let removeLButton = e.target.closest("remove-image")
      let url = '/sc/suppliers/' + removeLButton.dataset.supplierId +' /images/' + removeLButton.dataset.imageId
      var request = new XMLHttpRequest();
      request.open("DELETE", url);
      request.onreadystatechange = function() {
        removeLButton.closest(".image").remove();
      }
      request.send();
    }
  }
}
