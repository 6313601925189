import { Controller } from "stimulus"

export default class extends Controller { 
  static targets = ["button", "menu"];
  connect() {
    console.log("connected");
  }

  toggle() {  

    console.log("toggle");
    if (this.menuTarget.classList.contains("active")) {
      this.menuTarget.classList.remove("active");
    } else {
      this.menuTarget.classList.add("active");
    }
  }
}
