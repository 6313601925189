import { Controller } from "stimulus"

export default class extends Controller {
  copySharedLink() {
    let copyLink = document.getElementById("shared_link");
    copyLink.select();
    document.execCommand("copy");

    this.toggleTooltip()
    setTimeout(this.toggleTooltip, 1000)
  }

  toggleTooltip() {
    let tooltip = document.querySelector('.tooltip')

    if (tooltip.classList.contains('active')) {
      tooltip.classList.remove('active');
    } else {
      tooltip.classList.add('active');
    }
  }
}
